import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 900px;
  margin: 0 24px;
  width: 100%;
  position: relative;
  @media (max-width: 1000px) {
    margin: 0 5vw;
  }
`;

export default Wrapper;
