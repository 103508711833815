import React from 'react';
import styled, { keyframes, css } from 'styled-components';

const Section = props => (
  <SectionContainer {...props}>
    {props.children}
    {props.dotDetail ? (
      <DotDetail dotDetail={props.dotDetail}>
        <svg
          width="88"
          height="88"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="#FFF300">
            <rect width="4" height="4" />
            <rect x="12" width="4" height="4" />
            <rect x="24" width="4" height="4" />
            <rect x="36" width="4" height="4" />
            <rect x="48" width="4" height="4" />
            <rect x="60" width="4" height="4" />
            <rect x="72" width="4" height="4" />
            <rect y="12" width="4" height="4" />
            <rect x="12" y="12" width="4" height="4" />
            <rect x="24" y="12" width="4" height="4" />
            <rect x="36" y="12" width="4" height="4" />
            <rect x="48" y="12" width="4" height="4" />
            <rect x="60" y="12" width="4" height="4" />
            <rect y="24" width="4" height="4" />
            <rect x="12" y="24" width="4" height="4" />
            <rect x="24" y="24" width="4" height="4" />
            <rect x="36" y="24" width="4" height="4" />
            <rect x="48" y="24" width="4" height="4" />
            <rect y="36" width="4" height="4" />
            <rect x="12" y="36" width="4" height="4" />
            <rect x="24" y="36" width="4" height="4" />
            <rect x="36" y="36" width="4" height="4" />
            <rect y="48" width="4" height="4" />
            <rect x="12" y="48" width="4" height="4" />
            <rect x="24" y="48" width="4" height="4" />
            <rect y="60" width="4" height="4" />
            <rect x="12" y="60" width="4" height="4" />
            <rect y="72" width="4" height="4" />
            <rect y="84" width="4" height="4" />
            <rect x="12" y="72" width="4" height="4" />
            <rect x="24" y="60" width="4" height="4" />
            <rect x="36" y="48" width="4" height="4" />
            <rect x="48" y="36" width="4" height="4" />
            <rect x="60" y="24" width="4" height="4" />
            <rect x="72" y="12" width="4" height="4" />
            <rect x="84" width="4" height="4" />
          </g>
        </svg>
      </DotDetail>
    ) : null}
  </SectionContainer>
);

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const DotDetail = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  padding: 10px;
  ${props =>
    props.dotDetail === 'right'
      ? `
        svg {
          float: right;
          transform: rotate(90deg);
        }`
      : null}
  @media (max-width: 1200px) {
    opacity: 0.3;
  }
`;

const SectionContainer = styled.div`
  display: block;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  background-color: ${props => props.backgroundColor};
  font-family: Electrolize, sans-serif;
  color: white;
  position: relative;
  @media (max-width: 680px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  a {
    color: white;
  }
  ${props => props.noPaddingTop && `padding-top: 0 !important;`}
  ${props => props.noPaddingBottom && `padding-bottom: 0 !important;`}
  ${props => props.center && `text-align: center;`}
  ${props =>
    props.squareDetail
      ? css`
          :after {
            z-index: -1;
            ${props.squareDetail}: ${0 - 105}px;
            top: calc(50% - 105px);
            content: '';
            width: 210px;
            height: 210px;
            position: absolute;
            border: 70px solid #fff300;
            animation: ${rotate} 60s linear infinite;
            @media (max-width: 1200px) {
              opacity: 0.3;
            }
          }
        `
      : null}
  ${props =>
    props.firstSquareDetail
      ? css`
          :after {
            z-index: -1;
            ${props.firstSquareDetail}: ${0 - 105}px;
            top: calc(25% - 105px);
            content: '';
            width: 210px;
            height: 210px;
            position: absolute;
            border: 70px solid #fff300;
            animation: ${rotate} 60s linear infinite;
            @media (max-width: 1200px) {
              opacity: 0.3;
            }
          }
        `
      : null}
  ${props =>
    props.secondSquareDetail
      ? css`
          :before {
            z-index: -1;
            ${props.secondSquareDetail}: ${0 - 105}px;
            top: calc(75% - 105px);
            content: '';
            width: 210px;
            height: 210px;
            position: absolute;
            border: 70px solid #fff300;
            animation: ${rotate} 60s linear infinite;
            @media (max-width: 1200px) {
              opacity: 0.3;
            }
          }
        `
      : null}    
`;

export default Section;
