import React from 'react';

// styled
import Section from '../components/styled/Section';
import Wrapper from '../components/styled/Wrapper';
import DateText from '../components/styled/DateText';

// assets
import AssertLogo from '../assets/assertjs-logo-yellow.png';

const Header = () => (
  <Section>
    <Wrapper>
      <img
        src={AssertLogo}
        style={{ width: 560, margin: '0 auto', display: 'block' }}
      />
      <DateText>September 12, 2019, Toronto</DateText>
    </Wrapper>
  </Section>
);

export default Header;
