import styled from 'styled-components';

const Superscript = styled.h1`
  font-family: Orbitron;
  font-style: normal;
  font-weight: 900;
  font-size: 55px;
  line-height: 71px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff300;
  @media (max-width: 680px) {
    font-size: 41.25px;
    line-height: 53.25px
  }
  ${props => props.noMargin && `margin: 0;`}
  ${props => props.center && `text-align: center;`}
  ${props =>
    props.small &&
    `
      font-size: 36px;
      line-height: 47px;
      letter-spacing: 10px;
      @media (max-width: 680px) {
        font-size: 27px;
        line-height: 35.25px;
        letter-spacing: 7.5px;
      }
  `}
  ${props =>
    props.smaller &&
    `
      font-size: 24px;
      line-height: 31px;
      letter-spacing: 10px;
      @media (max-width: 680px) {
        font-size: 18px;
        line-height: 23.25px;
        letter-spacing: 7.5px;
      }
  `}
`;
export default Superscript;
