import styled from 'styled-components';

const Row = styled.div`
  display: block;
  width: 100%;
  display: flex;
  flex-direction: row;
  ${props => props.paddingTop && `padding-top: 80px;`}
  @media (max-width: 680px) {
    display: block;
    flex-direction: column;
  }
`;

export default Row;
