import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import assertLogo from '../assets/assertjs-white.png';

class Nav extends React.Component {
  state = {
    navOpen: false,
  };
  render() {
    const { selected } = this.props;
    const { navOpen } = this.state;
    return (
      <NavContainer id="Nav">
        <NavWrapper>
          <Logo to="/">
            <img src={assertLogo} />
          </Logo>
          <Links>
            <LinksContainer navOpen={navOpen}>
              <NavExternalLink
                href="https://shop.assertjs.com/"
                target="_blank"
                selected={selected}
              >
                Shop
              </NavExternalLink>
              <NavLink to="/training" selected={selected}>
                Training
              </NavLink>
              <NavLink to="/speakers" selected={selected}>
                Speakers
              </NavLink>
              <NavLink to="/location" selected={selected}>
                Location
              </NavLink>
              <NavLink to="/code-of-conduct" selected={selected}>
                Code of Conduct
              </NavLink>
              <NavExternalLink
                href="https://2018.assertjs.com/"
                target="_blank"
                selected={selected}
              >
                2018
              </NavExternalLink>
              <RegisterLink to="/tickets">Tickets</RegisterLink>
              {navOpen ? (
                <Close onClick={() => this.setState({ navOpen: !navOpen })}>
                  Close
                </Close>
              ) : null}
            </LinksContainer>
            <Mobile>
              <MobileBurger
                onClick={() => this.setState({ navOpen: !navOpen })}
              >
                Menu
              </MobileBurger>
              <RegisterLink to="/tickets">Tickets</RegisterLink>
            </Mobile>
          </Links>
        </NavWrapper>
      </NavContainer>
    );
  }
}

const MobileBurger = styled.div`
  font-family: 'Electrolize', sans-serif;
  color: white;
  line-height: 40px;
  display: inline-block;
  width: 80px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
`;

const Mobile = styled.div`
  display: none;
  flex-direcction: row;
  text-align: right;
  padding-top: 20px;
  @media (max-width: 1000px) {
    display: block;
  }
`;

const Close = styled.div`
  width: 80px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  font-family: 'Electrolize', sans-serif;
  justify-content: center;
  align-items: center;
  color: white;
  border-bottom: 2px solid white;
  border-left: 2px solid white;
  display: none;
  @media (max-width: 1000px) {
    display: flex;
  }
`;

const NavLink = styled(Link)`
  display: inline-block;
  border-bottom: none;
  font-family: 'Electrolize', sans-serif;
  font-size: 12px;
  color: white !important;
  border-bottom: 2px solid transparent;
  font-weight: 600;
  margin-left: 24px;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 24px;
  cursor: pointer;
  ${props =>
    props.selected === props.to
      ? `
        color: #FFF300 !important;
        border-bottom: 2px solid #FFF300;
        `
      : null}
`;

const NavExternalLink = styled.a`
  display: inline-block;
  border-bottom: none;
  font-family: 'Electrolize', sans-serif;
  font-size: 12px;
  color: white !important;
  border-bottom: 2px solid transparent;
  font-weight: 600;
  margin-left: 24px;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 24px;
  cursor: pointer;
`;

const RegisterLink = styled(Link)`
  display: inline-block;
  border-bottom: none;
  font-family: 'Electrolize', sans-serif;
  font-size: 12px;
  color: white !important;
  font-weight: 600;
  margin-left: 24px;
  text-decoration: none;
  border: 2px solid #fff300 !important;
  line-height: 12px;
  text-transform: uppercase;
  padding: 6px 12px;
`;

const NavContainer = styled.div`
  background-color: rgba(19, 19, 19, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  flex-direction: row;
  z-index: 999;
`;

const NavWrapper = styled.div`
  max-width: 900px;
  width: 100%;
  width: 100%;
  flex-direction: row;
  display: flex;
  @media (max-width: 1000px) {
    margin: 0 5vw;
  }
`;

const Links = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const LinksContainer = styled.div`
  text-align: right;
  justify-content: center;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1000px) {
    ${props =>
      props.navOpen
        ? `
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 2000px;
        background-color: black;
        padding-top: 50px;
        padding-left: 24px;
        padding-right: 24px;
        && {
          a {
            margin-left: 0;
            display: block;
            text-align: center;
            border-bottom: none;
            font-size: 24px;
            line-height: 34px;
            margin-bottom: 24px;
          }
        }
        
      `
        : `display: none;`}
  }
`;

const Logo = styled(Link)`
  line-height: 75px;
  border-bottom: none !important;
  text-decoration: none;
  min-width: 100px;
  img {
    width: 100px;
    margin-bottom: 0;
    margin-top: 24px;
  }
`;

export default Nav;
