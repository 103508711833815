import styled from 'styled-components';

const Column = styled.div`
  display: block;
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: column;
  ${props => props.shrink && `flex-grow: 0; margin-right: 24px;`}
`;

export default Column;
