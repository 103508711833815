import styled from 'styled-components';

const DateText = styled.div`
  font-family: Electrolize;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 24px;
  @media (max-width: 680px) {
    font-size: 18px;
  }
`;

export default DateText;
