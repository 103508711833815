import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import ogImageDefault from '../assets/og-image.jpg';

function SEO({ description, lang, meta, keywords, title, ogImage }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description;
        const thisOgImage = ogImage !== null ? ogImage : ogImageDefault;
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title || data.site.siteMetadata.title}
            titleTemplate={
              title ? `%s | ${data.site.siteMetadata.title}` : null
            }
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title || data.site.siteMetadata.title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: `https://www.assertjs.com` + thisOgImage,
              },
              {
                property: `og:image:secure_url`,
                content: `https://www.assertjs.com` + thisOgImage,
              },
              {
                property: `og:site_name`,
                content: `Assert(js), the JS testing conference.`,
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
              {
                name: `twitter:image`,
                content: `https://www.assertjs.com` + thisOgImage,
              },
              {
                name: `twitter:creator`,
                content: `@okg_io`,
              },
              {
                name: `twitter:site`,
                content: `@okg_io`,
              },
              {
                name: 'twitter:image:alt',
                content: 'Assert(js), the JS testing conference.',
              },
              {
                name: `twitter:title`,
                content: title || data.site.siteMetadata.title,
              },
              {
                name: `twitter:text:title`,
                content: title || data.site.siteMetadata.title,
              },

              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                    name: `keywords`,
                    content: keywords.join(`, `),
                  }
                  : [],
              )
              .concat(meta)}
          />
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  title: '',
  ogImage: null,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  ogImage: PropTypes.string,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
