import React from 'react';
import styled from 'styled-components';

class Footer extends React.Component {
  render() {
    return (
      <FooterContainer>
        <Wrapper>
          <Column>
            <div>© All rights reserved - assert(js) 2019</div>
          </Column>
          <Column style={{ justifyContent: 'flex-end', paddingRight: 0 }}>
            <div>
              <a href="https://twitter.com/assertjs">Twitter</a> -{' '}
              <a href="https://medium.com/assert-js-testing-conf">Medium</a> -{' '}
              <a href="mailto:assertjs@okgrow.com">assertjs@okgrow.com</a>
            </div>
          </Column>
        </Wrapper>
      </FooterContainer>
    );
  }
}

const Column = styled.div`
  display: flex;
  padding: 12px;
  flex-grow: 1;
  flex-basis: 0;
  &:first-child {
    padding-left: 0;
    @media (max-width: 680px) {
      padding-left: 0px;
    }
  }
`;

const FooterContainer = styled.div`
  font-family: Electrolize, sans-serif;
  font-size: 12px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: row;
  padding: 100px 0;
  text-transform: uppercase;
  a {
    color: white !important;
    border-bottom: 2px solid #444 !important;
    display: inline-block;
    line-height: 24px;
    text-decoration: none;
  }
  @media (max-width: 680px) {
    padding: 50px 0;
  }
`;

const Wrapper = styled.div`
  border-top: 2px solid #444;
  max-width: 900px;
  margin: 0 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  @media (max-width: 1000px) {
    width: inherit;
    margin: 0 5vw;
  }
  @media (max-width: 680px) {
    flex-direction: column;
  }
`;

export default Footer;
